import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import {Col, Container, Row} from "react-bootstrap";
import { injectIntl } from "gatsby-plugin-react-intl"
import HeroPages from "../../components/heroes/hero-pages";
import SimpleCard from "../../components/misc/simple-card";
import AboutData from "../../content/about/index.json";
import TextBlock from "../../components/misc/text-block";

const AboutIndexPage = ({ intl, location }) => (
  <Layout>
    <Seo
        lang={intl.locale}
        title={AboutData[intl.locale].title}
        description={AboutData[intl.locale].text}
        pathname={location.pathname}
    />
    <HeroPages
      theme={`primary`}
      content={AboutData[intl.locale]}
    />

    <Container>
        <Row>
            <TextBlock content={AboutData[intl.locale].intro} />
        </Row>
        <Row>
          {AboutData[intl.locale].team.map((item, i) => {

              return (
                  <Col key={i} md={4}>
                      <SimpleCard content={item} />
                  </Col>
              )

          })}
        </Row>
    </Container>
  </Layout>
)

export default injectIntl(AboutIndexPage)
